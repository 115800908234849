@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+KR:500,700&subset=korean");

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 700;
  src: local('Spoqa Han Sans Bold'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansBold.woff2) format('woff2'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansBold.woff) format('woff'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 400;
  src: local('Spoqa Han Sans Regular'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansRegular.woff2) format('woff2'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansRegular.woff) format('woff'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 300;
  src: local('Spoqa Han Sans Light'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansLight.woff2) format('woff2'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansLight.woff) format('woff'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 100;
  src: local('Spoqa Han Sans Thin'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansThin.woff2) format('woff2'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansThin.woff) format('woff'),
  url(https://cdn.finda.co.kr/font/SpoqaHanSansThin.ttf) format('truetype');
}