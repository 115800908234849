/*==================== FINDA COLORS ====================*/
$finda-blue: #0084dd;
$finda-darkblue: #0f5d91;
$finda-deepblue: #003356;
$finda-black: #2a3b47;
$finda-lightblack: #476173;
$finda-darkgray: #9c9c9c;
$finda-lightgray: #d5d5d5;
$finda-gray: #606060;
$finda-lightgreen: #00bfcc;
$finda-accordion: #fbfbfb;
$sub-shadow: #ededed;
$yellow: #ffb700;
$finda-tooltip: rgba(0, 0, 0, .75);
$light-gray: #F5F7F9;
$dark-gray: #778995;
$mid-gray: #A1B2BD;
$norm-gray: #DEE4E8;
$dark-blue: #0F5D91;
$deep-gray: #476173;
$light-blue: #eef8ff;

$finda-red: #F36B50;
$finda-yellow: #FBB117;

$domus-orange: #f69444;
$domus-yellow: #F7C340;
$domus-blue: #378dbc;
$domus-red: #ff001e;

$default-padding: 0 1.4rem;
$default-padding-horizontal: 1.4rem;
$nav-height: 50px;
$nav-z-index: 100;
$max-width: 1080px;

/*======================= media =======================*/

$small-w: 376px;
$medium-w: 768px;
$large-w: 1024px;
$x-large-w: 1200px;
$xx-large-w: 1400px;
$wide-w: 1600px;
$x-wide-w: 1920px;

$breakpoints: (
  small: $small-w,
  medium: $medium-w,
  large: $large-w,
  x-large: $x-large-w,
  xx-large: $xx-large-w,
  wide: $wide-w,
  x-wide: $x-wide-w
);