@import 'main';

:global {
  html {
    height: 100%;
    overflow: hidden;
  }

  body {
    height: 100%;
    overflow: auto;

    background-color: #fff;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }


  * {
    font-family: "Apple SD Gothic Neo", AppleSDGothicNeo, "Noto Sans CJK KR", "Spoqa Han Sans", 
      "NanumGothic", "Malgun Gothic", "맑은 고딕", 돋움, dotum, arial, sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    text-decoration: none;
    color: $finda-darkblue;
    // border-bottom: 1px solid transparent;
    transition-property: border-color, color, font-weight;
    transition-duration: .32s;
    transition-timing-function: ease;

    &:hover {
      font-weight: 700;
    }

    &:visited {
      color: $finda-darkblue;
    }
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;

    transition: transform .2s ease;
  }

  #root {
    position: relative;
  }

  input {
    font-family: inherit;
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }

  .ReactModalPortal * {
    &:focus {
      outline: 0;
    }
  }

  .calendar {
    display: block;
    position: absolute !important;
    width: calc(100% - #{$default-padding-horizontal}) !important;

    background-color: #fff;
  
    max-width: $max-width;
    left: 0;
    right: 0;
    margin: 0 auto;
  
    border-radius: 16px !important;
    box-shadow: 0px 13px 18px 0px rgba(0, 0, 0, 0.1) !important;
  }

  .rc-calendar {
    border: none !important;
  }
  .rc-calendar * {
    font-family: "Apple SD Gothic Neo", AppleSDGothicNeo, "Noto Sans CJK KR", "Spoqa Han Sans", 
    "NanumGothic", "Malgun Gothic", "맑은 고딕", 돋움, dotum, arial, sans-serif !important;

    a:hover {
      font-weight: 500 !important;
    }
  }

  .rc-calendar-date-panel, .rc-calendar-panel {
    overflow: scroll;
  }

  .rc-calendar, .rc-calendar-date-panel, .rc-calendar-panel {
    max-height: 70vh !important;
  }

  .rc-calendar-column-header-inner {
    font-size: 15px !important;
    color: $finda-darkgray !important;
  }
  .rc-calendar-header {
    border: none !important;
    height: 100% !important;
    padding:20px 10px 8px !important;
  }
  .rc-calendar-body {
    height: 100% !important;
    padding: 10px 5px 32px!important;
    
  }
  .rc-calendar-date {
    color: $dark-gray !important;
    border-radius: 50px !important;
    margin: .4em auto !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 34px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .rc-calendar-today .rc-calendar-date {
    border: none !important;
    background: transparent !important;
  }

  .rc-calendar-selected-date .rc-calendar-date {
    background: none !important;
    color: $norm-gray !important;
  }

  .rc-calendar-selected-day .rc-calendar-date {

    background: transparent !important;
    // background: $finda-darkblue !important;
    // color: #fff !important;
  }

  .rc-calendar-disabled-cell .rc-calendar-date {
    background: none !important;
    color: $norm-gray !important;
  }

  .rc-calendar-last-month-cell .rc-calendar-date, .rc-calendar-next-month-btn-day .rc-calendar-date {
    color: $norm-gray !important;
  }

  .rc-calendar-year-select, .rc-calendar-month-select, .rc-calendar-day-select {
    font-size: 16px !important;
    color: $finda-lightblack !important;
  }

  .rc-calendar-prev-month-btn:hover, .rc-calendar-next-month-btn:hover, .rc-calendar-prev-year-btn:hover, .rc-calendar-next-year-btn:hover {
    color: $finda-lightblack !important;
  }

  .rc-calendar-day-select, .rc-calendar-month-select, .rc-calendar-year-select {
    padding: 0 4px !important;
  }

  .rc-calendar-header .rc-calendar-prev-month-btn {
    left: 10px !important;
    &:after {
      content: '<' !important;
    }
  }
  .rc-calendar-header .rc-calendar-next-month-btn {
    right: 10px !important;
    &:after {
      content: '>' !important;    
    }
  }
  .rc-calendar-prev-month-btn, .rc-calendar-next-month-btn, .rc-calendar-prev-year-btn, .rc-calendar-next-year-btn {
    font-size: 22px !important;
    color: $finda-lightblack !important;
  }
  .rc-calendar-next-year-btn:after {
    content: none !important;
  }
  .rc-calendar-prev-year-btn:after {
    content: none !important;
  }
}