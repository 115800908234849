@import 'variables';

.calendarDate {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  color: $dark-gray;
  margin: .5em auto;
  font-size: 17px;
  font-weight: 500;
  border-radius: 50px;
  text-align: center;
  border: none;
  width: 24px;
  height: 24px;
  line-height: 28px;
  transition: margin .15s ease;
}