@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin fadeUp($length) {
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY($length);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

@mixin fadeIn() {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin fadeHorizontal($length) {
  @keyframes fadeHorizontal {
    from {
      opacity: 0;
      transform: translateX($length);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

}