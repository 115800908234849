@import 'main';

.alert {
  @include flex-center();
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  width: fit-content;
  max-width: 300px;
  margin: 22px auto;
  padding: 0 12px;
  border-radius: 50px;
  z-index: calc(#{$nav-z-index} + 1);
  background-color: $finda-red;
  opacity: 0;
  transform: translateY(-100px);
  
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .24);
  
  transition-property: opacity, transform;
  transition-duration: .4s;
  transition-timing-function: ease-out;

  &.show {
    transform: translateY(0px);
    opacity: 1;
  }
  
  .message {
    color: white;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    margin: .8em;
  }
}